import { NavItemModel } from "@/models/nav-iten";

export const navItems: NavItemModel[] = [
  {
    icon: 'new-search',
    title: 'New Search',
    link: '/new-search',
  },
  {
    icon: 'keywords',
    title: 'Keywords',
    link: '/keywords',
  },
  {
    icon: 'icon-search-black',
    title: 'Black List Search',
    link: '/black-list-search',
  },
  {
    icon: 'icon-hidden',
    title: 'Hidden Items',
    link: '/hidden-items',
  },
  {
    icon: 'saved-search',
    title: 'Saved Searches',
    link: '/saved-searches',
    display: false,
  },
  {
    icon: 'unsorted',
    title: 'Unsorted Items',
    link: '/unsorted-items',
  },
  {
    icon: 'unreported',
    title: 'Client unreported items',
    link: '/unreported-from-clients',
  },
  {
    icon: 'unreported',
    title: 'Unreported Items',
    link: '/unreported-items',
  },
  {
    icon: 'reported',
    title: 'Reported Items',
    link: '/reported-items',
  },
  {
    icon: 'archive',
    title: 'Archives',
    link: '/archives-items',
  },
  {
    icon: 'white-list',
    title: 'Whitelist',
    link: '/whitelist',
  },
  {
    icon: 'black-list',
    title: 'Blacklist',
    link: '/blacklist',
  },
  {
    icon: 'reports',
    title: 'Client Reports',
    link: '/client-reports',
    display: false,
  },
  {
    icon: 'statistics2',
    title: 'Statistics',
    link: '/statistics',
  },
]

export const bottomNavItems: NavItemModel[] = [
  {
    icon: 'ignored-keywords',
    title: 'Ignored Keywords',
    link: '/ignored-keywords',
  },
  {
    icon: 'reports',
    title: 'Client Templates',
    link: '/client-templates',
  },
]
